import React from "react";
import PropTypes from "prop-types";
import { Container, Heading, Text, Divider, Box } from "theme-ui";
import MainLayout from "../templates/MainLayout";
import SEO from "../components/seo";
import Header from "../components/Header";

const NotFoundPage = ({ location: { pathname } }) => (
  <MainLayout>
    <SEO pathname={pathname} title="404: Not found" />
    <Header
      pathname={pathname}
      sx={{ background: "linear-gradient(to right top, #07112b, #142b53)" }}
    />
    <Box px="3">
      <Container py="6">
        <Heading as="h1" variant="heading.three">
          404: Page Not Found
        </Heading>
        <Divider py="3" variant="divider.light" />
        <Text mt="3" variant="body.normal">
          You just hit a route that doesn&#39;t exist... the sadness.
        </Text>
      </Container>
    </Box>
  </MainLayout>
);

NotFoundPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default NotFoundPage;
